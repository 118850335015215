import React, { useState, useEffect } from "react";
import moment from "moment";
import { Formik, useFormikContext } from "formik";
import Box from "@mui/material/Box";
import Radio from "@mui/material/Radio";
import MUIButton from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Checkbox, Divider } from "@mui/material";
import Iconify from "components/iconify";

import PhoneNumber from "components/phoneInput";
import CalendarField from "components/calendarField";
import SelectOptions from "components/selectOptions";
import Badge from "components/badge";
import BannerInput from "components/bannerInput";
import Button from "components/button";
import FileInput from "components/fileInput";
import { AddExistingTravelersModal } from "components/app-modals";

import { Select } from "components/hook-form";

import { ReactComponent as AirPlane } from "assets/images/airplate.svg";
import { ReactComponent as Cabinbaggage } from "assets/images/cabinbaggage.svg";
import { ReactComponent as ArrowDown } from "assets/images/arrowDown.svg";
import { ReactComponent as Calendar } from "assets/images/calendar.svg";
import { Typography } from "@mui/material";
import { checkISAdminOrEmployee, duration } from "utils/Utils";

import { getCountryCode } from "utils/Currency&Language";
import { countries } from "assets/data";

function PassengersDetails({
  state,
  travelersInit,
  validationSchema,
  handelInfoSubmit,
  setCode,
  source,
}) {
  const [checked, setChecked] = useState(false);
  const [errImg, setErrImg] = useState(false);
  const [validateChecked1, setValidateChecked1] = useState(false);
  const [validateChecked2, setValidateChecked2] = useState(false);
  const [validateChecked3, setValidateChecked3] = useState(false);

  const [checkedEmail, setCheckedEmail] = useState(false);

  const FocusError = () => {
    const { errors, isSubmitting, isValidating } = useFormikContext();

    useEffect(() => {
      if (isSubmitting && !isValidating) {
        let keys = Object.keys(errors);

        if (keys.length > 0) {
          const selector = `[name=${keys[0]}]`;
          const errorElement = document.querySelector(selector);

          if (errorElement) {
            errorElement.scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
            errorElement.focus({ preventScroll: true });
          }
        }
      }
    }, [errors, isSubmitting, isValidating]);

    return null;
  };

  const gender = ["Male", "Female"];

  const handleChange = () => {
    setChecked(!checked);
  };

  return (
    <div>
      <div className="card-card">
        <div>
          <p className="medium-font-size">Trip summary</p>
        </div>
        <div className="journey-details">
          {state?.flight?.itineraries?.map((item, index) => (
            <div className="itinerary-trip" key={index}>
              <div className="itinerary-trip-title">
                <span className="itinerary-depart">
                  {state?.flightDetails?.return === "Multi-city" ? (
                    <>Trip {index + 1}</>
                  ) : index === 0 ? (
                    "departure"
                  ) : (
                    "RETURN"
                  )}
                </span>
                <span className="itinerary-depart-time">
                  Duration :{duration(item?.duration)}
                </span>
              </div>
              {item?.segments?.map((segmant, index) => (
                <React.Fragment key={index}>
                  <p className="bold mb-2">
                    {
                      state?.dictionaries?.locations[
                        segmant?.departure?.iataCode
                      ]?.city
                    }

                    <span>
                      <svg
                        className="Icon__StyledIcon-sc-1det6wr-0 fJfkpT"
                        viewBox="0 0 24 24"
                        preserveAspectRatio="xMidYMid meet"
                      >
                        <path d="M17.043 8.683a.588.588 0 01.852 0l2.922 2.921c.242.244.242.609 0 .792l-2.922 2.922a.554.554 0 01-.426.182c-.366 0-.609-.243-.609-.609V12.9L3.9 12.9a.9.9 0 01-.893-.787L3 12a.9.9 0 01.9-.9l12.96-.001v-1.99c0-.146.039-.253.117-.353l.066-.073z"></path>
                      </svg>
                    </span>
                    {
                      state?.dictionaries?.locations[segmant?.arrival?.iataCode]
                        ?.city
                    }
                  </p>
                  <div className="itinerary-timeline">
                    <div>
                      <div className="itinerary-date">
                        <div>
                          <div className="itinerary-icon-wrapper">
                            <Calendar />
                          </div>
                          <p>
                            <strong>
                              {" "}
                              {moment(segmant?.departure?.at).format(
                                "ddd D MMM"
                              )}
                            </strong>
                          </p>
                        </div>
                        <div className="d-flex">
                          {state?.dictionaries?.airlineLogos[
                            segmant?.carrierCode
                          ][0]?.squareImage ? (
                            <img
                              className="results-card-logo"
                              src={
                                state?.dictionaries?.airlineLogos[
                                  segmant?.carrierCode
                                ][0]?.tailImage
                              }
                            />
                          ) : (
                            <img
                              className="results-card-logo"
                              src={
                                state?.dictionaries?.airlineLogos[
                                  segmant?.carrierCode
                                ][0]?.squareImage
                              }
                            />
                          )}
                          <Badge
                            className="teal"
                            title={
                              state?.flight?.travelerPricings[0]?.fareDetailsBySegment?.find(
                                (sege) => sege?.segmentId == segmant?.id
                              )?.cabin
                            }
                          />
                        </div>
                      </div>
                      <div className="itinerary-flight">
                        <div className="itinerary-milestone">
                          <div className="itinerary-milestone-icon">
                            <AirPlane />
                          </div>
                          <div className="itinerary-collapse">
                            <div
                              onClick={handleChange}
                              className={`collapse-container ${
                                checked ? "collapsed" : "non-collapse"
                              }`}
                            >
                              <div>
                                <p>
                                  {moment(segmant?.departure?.at).format(
                                    "H:mm"
                                  )}{" "}
                                  <span className="location">
                                    {
                                      state?.dictionaries.locations[
                                        segmant.departure.iataCode
                                      ].airport
                                    }
                                  </span>
                                </p>
                                <p className="flight-time-duration">
                                  {duration(segmant?.duration, item?.duration)}
                                </p>
                              </div>
                              <p className="small-font-size light-gray">
                                Flight no: {segmant?.number}
                              </p>
                              <p className="small-font-size light-gray">
                                Airline:{" "}
                                <span className="small-font-size light-gray">
                                  {
                                    state?.dictionaries?.carriers[
                                      segmant?.carrierCode
                                    ]
                                  }
                                </span>
                              </p>
                              <p className="small-font-size light-gray">
                                Aircraft:{" "}
                                <span className="small-font-size light-gray">
                                  {segmant?.aircraft?.code}
                                </span>
                              </p>

                              <p>
                                {moment(segmant?.arrival?.at).format("H:mm")}{" "}
                                <span className="location">
                                  {
                                    state?.dictionaries.locations[
                                      segmant.arrival.iataCode
                                    ].airport
                                  }
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <p className="arrival-time">
                      <strong>
                        {" "}
                        {moment(segmant?.arrival?.at).format("ddd D MMM")}
                      </strong>
                    </p>
                  </div>
                </React.Fragment>
              ))}
            </div>
          ))}
        </div>
        <div className="free-check">
          <p>
            <strong>Automatic flight check-in</strong>
          </p>
          <p className="small-font-size">
            Most airlines charge a fee for check-in at the airport if you forget
            to check in online. If you provide us with your passport/ID details,
            we'll check you in online and send your boarding passes to you for
            free.
          </p>
        </div>
      </div>
      <Formik
        initialValues={travelersInit}
        validationSchema={validationSchema}
        onSubmit={handelInfoSubmit}
        enableReinitialize={true}
      >
        {({
          handleChange,
          handleSubmit,
          errors,
          setFieldTouched,
          setFieldValue,
          setFieldError,
          touched,
          values,
        }) => (
          <>
            <div className="card-card">
              <div className="form-inputs">
                <p className="medium-font-size mb-24">Travelers Info</p>
                <FocusError />

                {state?.flight?.travelerPricings?.map((item, index) => (
                  <div className="position-relative" key={index}>
                    <p className="medium-font-size mb-24">
                      Primary passenger({item.travelerType})
                    </p>

                    {errImg && (
                      <p style={{ color: "red", marginBottom: 10 }}>{errImg}</p>
                    )}

                    <Box display="flex" alignItems="center" gap={1}>
                      {checkISAdminOrEmployee() && (
                        <AddExistingTravelersModal
                          index={index}
                          departureDate={
                            state?.flight?.itineraries[0]?.segments[0]
                              ?.departure?.at
                          }
                        />
                      )}

                      <FileInput
                        index={index}
                        setErrImg={setErrImg}
                        departureDate={
                          state?.flight?.itineraries[0]?.segments[0]?.departure
                            ?.at
                        }
                        hasProgress
                      />
                    </Box>
                    <p style={{ marginBottom: 10, marginTop: 10 }}>
                      Upload or Pick a clear image of Passport to get your info!
                    </p>

                    <Box display="flex" flexWrap={{ xs: "wrap", md: "nowrap" }}>
                      <BannerInput
                        mandatory={true}
                        placeholder="e.g. Harry James"
                        label="First Name"
                        onChange={handleChange(`firstName${index}`)}
                        error={
                          touched[`firstName${index}`] &&
                          errors[`firstName${index}`]
                        }
                        onBlur={() => setFieldTouched(`firstName${index}`)}
                        name={`firstName${index}`}
                        value={values[`firstName${index}`]}
                      />
                      <BannerInput
                        mandatory={true}
                        placeholder="e.g. Brown"
                        label="Last Name"
                        onChange={handleChange(`lastName${index}`)}
                        error={
                          touched[`lastName${index}`] &&
                          errors[`lastName${index}`]
                        }
                        name={`lastName${index}`}
                        onBlur={() => setFieldTouched(`lastName${index}`)}
                        value={values[`lastName${index}`]}
                      />
                    </Box>
                    <Box
                      display="flex"
                      flexWrap={{ xs: "wrap", md: "nowrap" }}
                      gap={2}
                    >
                      <CalendarField
                        valueDay={values[`dateOfBirthDay${index}`]}
                        valueMonth={values[`dateOfBirthMonth${index}`]}
                        valueYear={values[`dateOfBirthYear${index}`]}
                        error1={
                          touched[`dateOfBirthDay${index}`] &&
                          errors[`dateOfBirthDay${index}`]
                        }
                        error2={
                          errors[`dateOfBirthMonth${index}`] &&
                          touched[`dateOfBirthMonth${index}`]
                        }
                        error3={
                          errors[`dateOfBirthYear${index}`] &&
                          touched[`dateOfBirthYear${index}`]
                        }
                        nameDay={`dateOfBirthDay${index}`}
                        nameMonth={`dateOfBirthMonth${index}`}
                        nameYear={`dateOfBirthYear${index}`}
                        onBlurDay={() =>
                          setFieldTouched(`dateOfBirthDay${index}`)
                        }
                        onBlurMonth={() =>
                          setFieldTouched(`dateOfBirthMonth${index}`)
                        }
                        onBlurYear={() =>
                          setFieldTouched(`dateOfBirthYear${index}`)
                        }
                        onChangeDay={handleChange(`dateOfBirthDay${index}`)}
                        onChangeMonth={handleChange(`dateOfBirthMonth${index}`)}
                        onChangeYear={handleChange(`dateOfBirthYear${index}`)}
                        mandatory={true}
                        label="Date of birth"
                        index={index}
                        travelerType={item.travelerType}
                      />
                      <SelectOptions
                        mandatory
                        label="Gender"
                        options={gender}
                        name={`gender${index}`}
                        onChange={handleChange(`gender${index}`)}
                        error={
                          touched[`gender${index}`] && errors[`gender${index}`]
                        }
                        value={values[`gender${index}`]}
                        onBlur={() => setFieldTouched(`gender${index}`)}
                      />
                    </Box>

                    {item.travelerType !== "CHILD" &&
                      item.travelerType !== "HELD_INFANT" && (
                        <>
                          <Box
                            display="flex"
                            flexWrap={{ xs: "wrap", md: "nowrap" }}
                          >
                            <BannerInput
                              mandatory
                              placeholder="JohnDuo@gmail.com"
                              label="Email"
                              readOnly={source === "NDC"}
                              name={`emailAddress${index}`}
                              onChange={handleChange(`emailAddress${index}`)}
                              error={
                                touched[`emailAddress${index}`] &&
                                errors[`emailAddress${index}`]
                              }
                              onBlur={() =>
                                setFieldTouched(`emailAddress${index}`)
                              }
                              value={values[`emailAddress${index}`]}
                            />
                            <PhoneNumber
                              mandatory
                              label="Phone Number"
                              placeholder="009933466"
                              onChange={handleChange(`number${index}`)}
                              error={
                                touched[`number${index}`] &&
                                errors[`number${index}`]
                              }
                              setCode={setCode}
                              countryCode={[`countryCallingCode${index}`]}
                              name={`number${index}`}
                              onBlur={() => setFieldTouched(`number${index}`)}
                              value={values[`number${index}`]}
                            />
                          </Box>

                          {index !== 0 &&
                            state?.flight?.travelerPricings?.length > 1 && (
                              <>
                                <Box mt={-1.2} mb={1}>
                                  <FormControlLabel
                                    disabled={
                                      !values[`emailAddress${0}`] ||
                                      !values[`number${0}`]
                                    }
                                    sx={{
                                      "& .MuiTypography-root": {
                                        fontSize: "13px",
                                      },
                                    }}
                                    control={
                                      <Checkbox
                                        size="small"
                                        checked={checkedEmail}
                                        onChange={(event) => {
                                          setCheckedEmail(event.target.checked);

                                          if (event.target.checked) {
                                            state?.flight?.travelerPricings?.map(
                                              (item, ndx) => {
                                                if (ndx === 0) {
                                                  return;
                                                } else {
                                                  setFieldError(
                                                    `emailAddress${ndx}`,
                                                    null
                                                  );
                                                  setFieldError(
                                                    `number${ndx}`,
                                                    null
                                                  );
                                                  setFieldValue(
                                                    `emailAddress${ndx}`,
                                                    values[`emailAddress${0}`]
                                                  );
                                                  setFieldValue(
                                                    `number${ndx}`,
                                                    values[`number${0}`]
                                                  );

                                                  setFieldValue(
                                                    `countryCallingCode${ndx}`,
                                                    getCountryCode(
                                                      values[`number${0}`]
                                                    )?.countryCode
                                                  );

                                                  setFieldTouched(
                                                    `number${ndx}`,
                                                    false
                                                  );
                                                  setFieldTouched(
                                                    `emailAddress${ndx}`,
                                                    false
                                                  );
                                                }
                                              }
                                            );
                                          } else {
                                            state?.flight?.travelerPricings?.map(
                                              (item, ndx) => {
                                                if (ndx === 0) {
                                                  return;
                                                }

                                                setFieldValue(
                                                  `emailAddress${ndx}`,
                                                  ""
                                                );
                                                setFieldValue(
                                                  `number${ndx}`,
                                                  ""
                                                );
                                              }
                                            );
                                          }
                                        }}
                                      />
                                    }
                                    label="Add Same Email and Phone Number"
                                  />
                                </Box>
                              </>
                            )}
                        </>
                      )}

                    <Box display="flex" flexWrap={{ xs: "wrap", md: "nowrap" }}>
                      <BannerInput
                        label="Passport Number"
                        onChange={
                          values[`documents${index}`]
                            ? handleChange(`documents${index}[0].number`)
                            : () => {}
                        }
                        error={
                          touched[`documents${index}[0].number`] &&
                          errors[`documents${index}[0].number`]
                        }
                        name={`documents${index}[0].number`}
                        onBlur={() =>
                          setFieldTouched(`documents${index}[0].number`)
                        }
                        value={values[`documents${index}`][0]?.number}
                      />

                      <CalendarField
                        valueDay={values[`expDateDay${index}`]}
                        valueMonth={values[`expDateMonth${index}`]}
                        valueYear={values[`expDateYear${index}`]}
                        error1={
                          touched[`expDateDay${index}`] &&
                          errors[`expDateDay${index}`]
                        }
                        error2={
                          errors[`expDateMonth${index}`] &&
                          touched[`expDateMonth${index}`]
                        }
                        error3={
                          errors[`expDateYear${index}`] &&
                          touched[`expDateYear${index}`]
                        }
                        nameDay={`expDateDay${index}`}
                        nameMonth={`expDateMonth${index}`}
                        nameYear={`expDateYear${index}`}
                        onBlurDay={() => setFieldTouched(`expDateDay${index}`)}
                        onBlurMonth={() =>
                          setFieldTouched(`expDateMonth${index}`)
                        }
                        onBlurYear={() =>
                          setFieldTouched(`expDateYear${index}`)
                        }
                        onChangeDay={handleChange(`expDateDay${index}`)}
                        onChangeMonth={handleChange(`expDateMonth${index}`)}
                        onChangeYear={handleChange(`expDateYear${index}`)}
                        mandatory={true}
                        label="Passport Expiration date"
                        departureDate={
                          state?.flight?.itineraries[0]?.segments[0]?.departure
                            ?.at
                        }
                      />
                    </Box>

                    {values[`documents${index}`] && (
                      <Box
                        display="flex"
                        flexWrap={{ xs: "wrap", md: "nowrap" }}
                        sx={{
                          width: { md: "48%" },
                          gap: { md: "0px" },
                        }}
                      >
                        <Select
                          label="Issuance Country"
                          mandatory
                          options={countries.map((country) => country.label)}
                          getOptionLabel={(option) => option}
                          name={`documents${index}[0].issuanceCountry`}
                          type="country"
                          isOptionEqualToValue={(option, value) =>
                            option === value
                          }
                          renderOption={(props, option) => {
                            const { code, label, phone } = countries.filter(
                              (country) => country.label === option
                            )[0];

                            if (!label) {
                              return null;
                            }

                            return (
                              <li {...props} key={label}>
                                <Iconify
                                  key={label}
                                  icon={`circle-flags:${code.toLowerCase()}`}
                                  width={22}
                                  sx={{ mr: 1, maxWidth: 22, minWidth: 22 }}
                                />
                                {label} ({code})
                              </li>
                            );
                          }}
                        />

                        <Select
                          label="Nationality"
                          mandatory
                          options={countries.map((country) => country.label)}
                          getOptionLabel={(option) => option}
                          name={`documents${index}[0].nationality`}
                          type="country"
                          isOptionEqualToValue={(option, value) =>
                            option === value
                          }
                          renderOption={(props, option) => {
                            const { code, label, phone } = countries.filter(
                              (country) => country.label === option
                            )[0];

                            if (!label) {
                              return null;
                            }

                            return (
                              <li {...props} key={label}>
                                <Iconify
                                  key={label}
                                  icon={`circle-flags:${code.toLowerCase()}`}
                                  width={22}
                                  sx={{ mr: 1, maxWidth: 22, minWidth: 22 }}
                                />
                                {label} ({code})
                              </li>
                            );
                          }}
                        />
                      </Box>
                    )}

                    {item.travelerType === "ADULT" && (
                      <Box>
                        {(!item?.fareDetailsBySegment[0]?.includedCheckedBags
                          ?.quantity ||
                          item?.fareDetailsBySegment[0]?.includedCheckedBags
                            ?.quantity === 0) &&
                        !item?.fareDetailsBySegment[0]?.includedCheckedBags
                          ?.weight ? (
                          <div>
                            <p className="bold note-font">
                              <span className="orange-color"> Note:</span> this
                              reservation has no baggage included, to add a bag
                              to your reservation please call us or send an
                              email with your reference ID.{" "}
                            </p>
                          </div>
                        ) : (
                          <div>
                            <p className="medium-font-size mb-8 bolder">
                              Checked bags
                            </p>
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="space-between"
                              className="cabin-box-container active"
                            >
                              <Box display="flex" alignItems="center">
                                <Radio checked={true} />
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  flexWrap="wrap"
                                >
                                  <Cabinbaggage />
                                  <p>Checked bag</p>
                                  <p className="gray size">
                                    {item?.fareDetailsBySegment[0]
                                      ?.includedCheckedBags?.quantity ??
                                      "1"}{" "}
                                    ×{" "}
                                    {item?.fareDetailsBySegment[0]
                                      ?.includedCheckedBags?.weight ??
                                      "23"}{" "}
                                    {item?.fareDetailsBySegment[0]
                                      ?.includedCheckedBags?.weightUnit ?? "KG"}
                                  </p>
                                </Box>
                              </Box>
                              <p>Included</p>
                            </Box>
                          </div>
                        )}
                      </Box>
                    )}
                  </div>
                ))}
              </div>

              <Box display="flex" alignItems="center" my={2}>
                <Radio
                  checked={validateChecked1}
                  onClick={() => setValidateChecked1((prev) => !prev)}
                />
                <Typography>
                  I have verified that all the information on this form is
                  correct.
                </Typography>
              </Box>
              <Box display="flex" alignItems="flex-start" my={2}>
                <Radio
                  checked={validateChecked2}
                  onClick={() => setValidateChecked2((prev) => !prev)}
                />
                <Typography>
                  I acknowledge that if the passport image is not clearly
                  visible, the scanner may have generated invalid data, and I
                  may need to make adjustments if needed.
                </Typography>
              </Box>
              <Box display="flex" alignItems="flex-start" my={2}>
                <Radio
                  checked={validateChecked3}
                  onClick={() => setValidateChecked3((prev) => !prev)}
                />
                <Typography>
                  I have verified that I either possess a valid visa or do not
                  require a visa to travel to{" "}
                  {state?.flightDetails?.to?.split(",")?.length > 1
                    ? state?.flightDetails?.to?.split(",")[0]
                    : state?.flightDetails?.to}{" "}
                  {state?.flightDetails?.returnDate && (
                    <>
                      for the duration of{" "}
                      {moment(state?.flightDetails?.returnDate).diff(
                        moment(state?.flightDetails?.departureDate),
                        "days"
                      ) + 1}{" "}
                      days as specified in my reservation.
                    </>
                  )}{" "}
                </Typography>
              </Box>
            </div>

            <Button
              type="submit"
              onClick={handleSubmit}
              disabled={
                !validateChecked1 || !validateChecked2 || !validateChecked3
              }
              className="bigBtn primary-color booking"
            >
              Continue
              {state?.flightDetails?.return !== "One-way" && (
                <>- {state?.flightDetails?.totalDays + "   "} Days</>
              )}
              <ArrowDown />
            </Button>
          </>
        )}
      </Formik>
    </div>
  );
}

export default PassengersDetails;
