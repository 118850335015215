import React, { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import Radio from "@mui/material/Radio";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

import "./style.css";

import Input from "../bannerInput";
import Button from "../button";
import VerifyCode from "../verifyCode";
import PhoneNumber from "../phoneInput";
import { register } from "api/Auth";
import { MenuItem, Typography } from "@mui/material";

const SignUp = ({ handleClose }) => {
  const [err, setErr] = useState("");
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [showOtp, setShowOtp] = useState(false);
  const [checked, setChecked] = useState(false);

  const [email, setEmail] = useState("");

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required().label("First Name"),
    lastName: Yup.string().required().label("Last Name"),
    email: Yup.string().email().required().label("Email"),
    password: Yup.string().min(3).required().label("Password"),
    heardFrom: Yup.string().required().label("Heard From"),
    otherField: Yup.string().when("heardFrom", {
      is: "Other", // Condition when heardFrom is "Other"
      then: Yup.string()
        .required('This field is required when "Heard From" is "Other"')
        .label("This field"),
      otherwise: Yup.string().nullable(), // Optional or non-required when the condition does not match
    }),
  });

  const handelSubmit = async (values) => {
    setLoading(true);
    setErr("");
    values.phoneNumber = `+${values.phoneNumber}`;
    if (values.heardFrom === "Other") {
      values.heardFrom = values.otherField;
    }

    delete values.otherField;
    const res = await register({ body: values });
    if (!res.ok) {
      setErr(res?.data?.errorDetails);
    } else {
      setShowOtp(true);

      setEmail(values.email);
    }
    setLoading(false);
  };

  return (
    <>
      {!showOtp ? (
        <div className="login-modal">
          <h1 className="main-title">Create an account</h1>
          {err && (
            <Alert severity="error" sx={{ mb: 1 }}>
              {err}
            </Alert>
          )}
          <Formik
            initialValues={{
              firstName: "",
              lastName: "",
              email: "",
              password: "",
              phoneNumber: "",
              heardFrom: "",
              otherField: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handelSubmit}
          >
            {({
              handleChange,
              handleSubmit,
              errors,
              setFieldTouched,
              touched,
              values,
              setFieldValue,
            }) => (
              <>
                <Input
                  mandatory
                  onChange={(e) => {
                    const value = e.target.value || "";
                    setFieldValue("firstName", value.toLowerCase());
                  }}
                  error={touched.firstName && errors.firstName}
                  onBlur={() => setFieldTouched("firstName")}
                  name={"firstName"}
                  label="Enter your first name"
                />
                <Input
                  onChange={(e) => {
                    const value = e.target.value || "";
                    setFieldValue("lastName", value.toLowerCase());
                  }}
                  error={touched.lastName && errors.lastName}
                  onBlur={() => setFieldTouched("lastName")}
                  name={"lastName"}
                  mandatory
                  label="Enter your last name"
                />
                <Input
                  onChange={(e) => {
                    const value = e.target.value || "";
                    setFieldValue("email", value.toLowerCase());
                  }}
                  error={touched.email && errors.email}
                  onBlur={() => setFieldTouched("email")}
                  name={"email"}
                  mandatory
                  type="email"
                  label="Enter your Email"
                />
                <PhoneNumber
                  name={`phoneNumber`}
                  onChange={handleChange(`phoneNumber`)}
                  error={touched.phoneNumber && errors.phoneNumber}
                  mandatory
                  onBlur={() => setFieldTouched("phoneNumber")}
                  setCode={setCode}
                  label="Enter your Phone Number"
                />
                <Input
                  onChange={handleChange(`password`)}
                  error={touched.password && errors.password}
                  onBlur={() => setFieldTouched("password")}
                  name={"password"}
                  mandatory
                  type="password"
                  label="Enter your password"
                />

                <label>How did you heard about us?</label>

                <TextField
                  onChange={handleChange(`heardFrom`)}
                  error={touched.heardFrom && errors.heardFrom}
                  helperText={touched.heardFrom && errors.heardFrom}
                  onBlur={() => setFieldTouched("heardFrom")}
                  name={"heardFrom"}
                  select
                  variant="outlined"
                  sx={{
                    "& .MuiInputBase-root": {
                      boxShadow:
                        touched.heardFrom && errors.heardFrom
                          ? "rgb(210 28 28) 0px 0px 0px 1px"
                          : "rgb(186 199 213) 0px 0px 0px 1px",
                      borderRadius: "4px",
                      fontSize: "14px",
                      height: "44px",
                    },
                    mb: 1,
                  }}
                  size="small"
                >
                  <MenuItem value={"Google"}>Google</MenuItem>
                  <MenuItem value={"Facebook"}>Facebook</MenuItem>
                  <MenuItem value={"Instagram"}>Instagram</MenuItem>
                  <MenuItem value={"Referral from a Friend"}>
                    Referral from a Friend
                  </MenuItem>
                  <MenuItem value={"TV or Radio Ad"}>TV or Radio Ad</MenuItem>
                  <MenuItem value={"Newspaper or Magazine"}>
                    Newspaper or Magazine
                  </MenuItem>
                  <MenuItem value={"Flyer or Brochure"}>
                    Flyer or Brochure
                  </MenuItem>
                  <MenuItem value={"Other"}>Other</MenuItem>
                </TextField>
                {values["heardFrom"] === "Other" && (
                  <>
                    <label>Please Specify:</label>

                    <TextField
                      onChange={handleChange(`otherField`)}
                      error={touched.otherField && errors.otherField}
                      helperText={touched.otherField && errors.otherField}
                      onBlur={() => setFieldTouched("otherField")}
                      name={"otherField"}
                      variant="outlined"
                      sx={{
                        "& .MuiInputBase-root": {
                          boxShadow:
                            touched.otherField && errors.otherField
                              ? "rgb(210 28 28) 0px 0px 0px 1px"
                              : "rgb(186 199 213) 0px 0px 0px 1px",
                          borderRadius: "4px",
                          fontSize: "14px",
                          height: "44px",
                        },
                        mb: 1,
                      }}
                      size="small"
                    />
                  </>
                )}
                <Box display="flex" alignItems="center">
                  <Radio
                    checked={checked}
                    onClick={() => setChecked((prev) => !prev)}
                  />
                  <Typography>
                    I agree to the{" "}
                    <Typography
                      component="a"
                      href="/privacy-policy"
                      variant="subtitle2"
                      fontWeight="bold"
                      color="var(--primary-color)"
                      target="_blank"
                    >
                      {" "}
                      Privacy Policy .{" "}
                    </Typography>
                  </Typography>
                </Box>

                <Button
                  type="submit"
                  onClick={handleSubmit}
                  disabled={!checked}
                  className="bigBtn w-100 primary-color"
                >
                  {loading ? (
                    <CircularProgress sx={{ color: "#fff" }} size={22} />
                  ) : (
                    "Sign up "
                  )}
                </Button>
              </>
            )}
          </Formik>
          <p className="gray medium-font-size text-center">
            Already Have an account?{" "}
            <u
              onClick={() => {
                handleClose();
                window.location.hash = "#auth-required";
              }}
            >
              Log In
            </u>
          </p>
        </div>
      ) : (
        <VerifyCode email={email} handleClose={handleClose} />
      )}
    </>
  );
};

export default SignUp;
